<template>
  <div class="container mr-auto ml-0">
    <b-row class="mt-4">
      <b-col sm="12" lg="10" xl="8">
        <b-card no-body class="">
          <b-card-body class="p-4">
            <form @submit.prevent="validateBeforeSubmit">
              <h1>Add WTS</h1>
              <p class="text-muted">Enter WTS information</p>


              <b-input-group class="mt-3 mb-2">
                <b-input-group-prepend>
                  <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" v-validate="'required'" name="fname" v-model="fname" class="form-control" placeholder="First Name" />
              </b-input-group>
              <i v-show="errors.has('fname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('fname')" class="help is-danger">The first name is required.</span>

              <b-input-group class="mt-3 mb-2">
                <b-input-group-prepend>
                  <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="text" v-validate="'required'" name="lname" v-model="lname" class="form-control" placeholder="Last Name" />
              </b-input-group>
              <i v-show="errors.has('lname')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('lname')" class="help is-danger">The last name is required.</span>


              <hr>
              <b-input-group class="mt-3 mb-2">
                <b-input-group-prepend>
                  <b-input-group-text><i class="fa fa-phone fa-flip-horizontal"></i></b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="number" v-model="phone" v-validate="'required|digits:10'" name="phone" class="form-control" placeholder="Phone Number" />
              </b-input-group>
              <i v-show="errors.has('phone')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('phone')" class="help is-danger">{{ errors.first('phone') }}</span>

              <b-input-group class="mt-3 mb-2">
                <b-input-group-prepend>
                  <b-input-group-text><i class="fa fa-phone fa-flip-horizontal"></i></b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="number" v-model="fax" name="fax" class="form-control" placeholder="Fax Number" />
              </b-input-group>

              <hr>
              <b-input-group class="mt-3 mb-2">
                <b-input-group-prepend>
                  <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="email" v-model="email" v-validate="'required'" name="email" class="form-control" placeholder="Email" />
              </b-input-group>
              <i v-show="errors.has('email')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('email')" class="help is-danger">The email is required.</span>
              <b-input-group class="mt-3 mb-2">
                <b-input-group-prepend>
                  <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
                </b-input-group-prepend>
                <b-form-input type="password" v-model="password" v-validate="'required'" name="password" class="form-control" placeholder="Password" />
              </b-input-group>
              <i v-show="errors.has('password')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('password')" class="help is-danger">The password is required.</span>


              <b-button variant="success" class="mt-4" type="submit" block>Create Manager</b-button>
            </form>
          </b-card-body>
          <b-card-footer class="p-4">
            <b-row>
              <b-col cols="6">
                <pre>{{$data}}</pre>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>


<script>
export default {
  name: 'ManagerEditProject',
  data: function () {
    return {
      email: "devtest@rtnest.ca",
      password: "rtndev",
      fname: "Ali",
      lname: "Hafez",
      fax: "1234567890",
      phone: "1234567890"
    }
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = JSON.stringify({
            companyid: this.companyid,
            email: this.email,
            password: this.password,
            authority: this.authority,
            passlimit: this.passlimit,
            fname: this.fname,
            lname: this.lname,
            fax: this.fax,
            phone: this.phone,
          })
          this.$http.post('/admin/create/manager', data)
          .then(response => {
            if (response.status != 200) {
              this.$toasted.show('Error.', {type: 'error', duration: '3000'})
              this.error = true;
            }
            if (response.status === 200) {
              this.$router.push('/admin/all-managers')
            }
          })
          .catch(error => {
          })
          return;
        }
      });
    }
  }
}
</script>
