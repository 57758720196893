var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mr-auto ml-0" },
    [
      _c(
        "b-row",
        { staticClass: "mt-4" },
        [
          _c(
            "b-col",
            { attrs: { sm: "12", lg: "10", xl: "8" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": "" } },
                [
                  _c("b-card-body", { staticClass: "p-4" }, [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.validateBeforeSubmit($event)
                          }
                        }
                      },
                      [
                        _c("h1", [_vm._v("Add WTS")]),
                        _c("p", { staticClass: "text-muted" }, [
                          _vm._v("Enter WTS information")
                        ]),
                        _c(
                          "b-input-group",
                          { staticClass: "mt-3 mb-2" },
                          [
                            _c(
                              "b-input-group-prepend",
                              [
                                _c("b-input-group-text", [
                                  _c("i", { staticClass: "fa fa-user" })
                                ])
                              ],
                              1
                            ),
                            _c("b-form-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                name: "fname",
                                placeholder: "First Name"
                              },
                              model: {
                                value: _vm.fname,
                                callback: function($$v) {
                                  _vm.fname = $$v
                                },
                                expression: "fname"
                              }
                            })
                          ],
                          1
                        ),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("fname"),
                              expression: "errors.has('fname')"
                            }
                          ],
                          staticClass: "fa fa-exclamation-triangle"
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("fname"),
                                expression: "errors.has('fname')"
                              }
                            ],
                            staticClass: "help is-danger"
                          },
                          [_vm._v("The first name is required.")]
                        ),
                        _c(
                          "b-input-group",
                          { staticClass: "mt-3 mb-2" },
                          [
                            _c(
                              "b-input-group-prepend",
                              [
                                _c("b-input-group-text", [
                                  _c("i", { staticClass: "fa fa-user" })
                                ])
                              ],
                              1
                            ),
                            _c("b-form-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                name: "lname",
                                placeholder: "Last Name"
                              },
                              model: {
                                value: _vm.lname,
                                callback: function($$v) {
                                  _vm.lname = $$v
                                },
                                expression: "lname"
                              }
                            })
                          ],
                          1
                        ),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("lname"),
                              expression: "errors.has('lname')"
                            }
                          ],
                          staticClass: "fa fa-exclamation-triangle"
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("lname"),
                                expression: "errors.has('lname')"
                              }
                            ],
                            staticClass: "help is-danger"
                          },
                          [_vm._v("The last name is required.")]
                        ),
                        _c("hr"),
                        _c(
                          "b-input-group",
                          { staticClass: "mt-3 mb-2" },
                          [
                            _c(
                              "b-input-group-prepend",
                              [
                                _c("b-input-group-text", [
                                  _c("i", {
                                    staticClass:
                                      "fa fa-phone fa-flip-horizontal"
                                  })
                                ])
                              ],
                              1
                            ),
                            _c("b-form-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|digits:10",
                                  expression: "'required|digits:10'"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                name: "phone",
                                placeholder: "Phone Number"
                              },
                              model: {
                                value: _vm.phone,
                                callback: function($$v) {
                                  _vm.phone = $$v
                                },
                                expression: "phone"
                              }
                            })
                          ],
                          1
                        ),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("phone"),
                              expression: "errors.has('phone')"
                            }
                          ],
                          staticClass: "fa fa-exclamation-triangle"
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("phone"),
                                expression: "errors.has('phone')"
                              }
                            ],
                            staticClass: "help is-danger"
                          },
                          [_vm._v(_vm._s(_vm.errors.first("phone")))]
                        ),
                        _c(
                          "b-input-group",
                          { staticClass: "mt-3 mb-2" },
                          [
                            _c(
                              "b-input-group-prepend",
                              [
                                _c("b-input-group-text", [
                                  _c("i", {
                                    staticClass:
                                      "fa fa-phone fa-flip-horizontal"
                                  })
                                ])
                              ],
                              1
                            ),
                            _c("b-form-input", {
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                name: "fax",
                                placeholder: "Fax Number"
                              },
                              model: {
                                value: _vm.fax,
                                callback: function($$v) {
                                  _vm.fax = $$v
                                },
                                expression: "fax"
                              }
                            })
                          ],
                          1
                        ),
                        _c("hr"),
                        _c(
                          "b-input-group",
                          { staticClass: "mt-3 mb-2" },
                          [
                            _c(
                              "b-input-group-prepend",
                              [
                                _c("b-input-group-text", [
                                  _c("i", { staticClass: "fa fa-user" })
                                ])
                              ],
                              1
                            ),
                            _c("b-form-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "email",
                                name: "email",
                                placeholder: "Email"
                              },
                              model: {
                                value: _vm.email,
                                callback: function($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email"
                              }
                            })
                          ],
                          1
                        ),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("email"),
                              expression: "errors.has('email')"
                            }
                          ],
                          staticClass: "fa fa-exclamation-triangle"
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("email"),
                                expression: "errors.has('email')"
                              }
                            ],
                            staticClass: "help is-danger"
                          },
                          [_vm._v("The email is required.")]
                        ),
                        _c(
                          "b-input-group",
                          { staticClass: "mt-3 mb-2" },
                          [
                            _c(
                              "b-input-group-prepend",
                              [
                                _c("b-input-group-text", [
                                  _c("i", { staticClass: "fa fa-user" })
                                ])
                              ],
                              1
                            ),
                            _c("b-form-input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "password",
                                name: "password",
                                placeholder: "Password"
                              },
                              model: {
                                value: _vm.password,
                                callback: function($$v) {
                                  _vm.password = $$v
                                },
                                expression: "password"
                              }
                            })
                          ],
                          1
                        ),
                        _c("i", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("password"),
                              expression: "errors.has('password')"
                            }
                          ],
                          staticClass: "fa fa-exclamation-triangle"
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("password"),
                                expression: "errors.has('password')"
                              }
                            ],
                            staticClass: "help is-danger"
                          },
                          [_vm._v("The password is required.")]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "mt-4",
                            attrs: {
                              variant: "success",
                              type: "submit",
                              block: ""
                            }
                          },
                          [_vm._v("Create Manager")]
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "b-card-footer",
                    { staticClass: "p-4" },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { cols: "6" } }, [
                            _c("pre", [_vm._v(_vm._s(_vm.$data))])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }